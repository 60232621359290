/* eslint-disable react/no-danger */
import React from 'react'
import useStyles from './public-coupons-style'
import {
  Layout,
  Loading,
  SEO,
} from '../../ui'
import CouponMarketplaceCouponInfo from '../../ui/mini-cart-coupons/components/coupon-marketplace-coupon-info'
import _ from 'lodash'

function PublicCouponsView({
  canActions,
  computedGracePeriodExpiresAt,
  coupon,
  enableClock,
  enableValue,
  loading,
  qrCodeValue,
  seoDescription,
  seoTitle,
  startAt,
  status,
  statusLabel,
}) {
  const couponInfoProps = {
    canActions,
    coupon,
    isPublic: true,
    qrCodeValue: status === 'usable' ? qrCodeValue : null,
    showClock: enableClock,
    showQrCodeValue: enableValue,
    showQrCode: true,
    showStatusLabel: true,
    startAt,
    status,
    statusLabel,
    computedGracePeriodExpiresAt,
  }
  const styles = useStyles()

  if (loading) return <Loading />
  return (
    <Layout>
      <SEO description={seoDescription} title={seoTitle} />
      <article className={styles.contianer}>
        <div className={styles.coupon}>
          <CouponMarketplaceCouponInfo {...couponInfoProps} />
        </div>
        <div
          className={styles.terms}
          dangerouslySetInnerHTML={{ __html: _.get(coupon, 'terms', '') }}
        />
      </article>
    </Layout>
  )
}

export default PublicCouponsView
